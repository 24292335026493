<template>
  <div>
    <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>
    <v-card flat class="pa-3 mt-2">
      <!-- Modal -->

      <!-- Modal Role -->

      <v-card-text>
        <v-card-title class="text-no-wrap pt-1 ps-2"> UPDATE JENIS CUSTOMER</v-card-title>
        <form @submit.prevent="CreateData" ref="form">
          <v-row>
            <v-col cols="12" v-if="errors.length">
              <v-alert color="error" v-for="error in errors" :key="error" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning"> </v-icon>
                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">{{ error }}</p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field
                label="Nama Jenis Customer"
                v-model="jeniscustomers.jenis_customer"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-btn type="submit" color="primary" class="me-3 mt-4"> Update </v-btn>

              <router-link :to="{ name: 'jeniscustomer-index' }">
                <v-btn color="secondary" outlined class="mt-4" type="reset"> Cancel </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mdiCheckBold, mdiSlashForward } from '@mdi/js'
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/superadmin/dashboard',
        },
        {
          text: 'Jenis Customer',
          disabled: false,
          href: '/superadmin/jeniscustomer',
        },
        {
          text: 'Update Jenis Customer',
          disabled: true,
        },
      ],

      icons: {
        mdiCheckBold,
        mdiSlashForward,
      },

      errors: [],

      jeniscustomers: {
        jenis_customer: '',
      },
    }
  },
  mounted() {
    this.$isLoading(true)
    setTimeout(() => {
      this.getJenisCustomer()
      this.$isLoading(false)
    }, 2000)
  },
  methods: {
    getJenisCustomer() {
      // let uri = `/api/admin/datacabangaktif/${this.$route.params.id_cabang}/show`
      let uriUsers =
        process.env.VUE_APP_ROOT_API + `/api/superadmin/jeniscustomer/${this.$route.params.id_jeniscustomer}/show`
      this.axios.get(uriUsers).then(response => {
        this.jeniscustomers = response.data.jeniscustomer
        // console.log(this.users.jeniscustomers)
      })
    },

    CreateData(e) {
      // console.log('id_cabang: ' + this.users.id_cabang)

      if (this.$data.jeniscustomers.jenis_customer.length === 0) {
        // this.errors.push('Nama Role harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Nama jenis customer wajib diisi !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else {
        let uri =
          process.env.VUE_APP_ROOT_API + `/api/superadmin/jeniscustomer/${this.$route.params.id_jeniscustomer}/update`
        this.axios
          .post(uri, this.jeniscustomers)
          .then(response => {
            this.$swal.fire({
              title: 'Success',
              text: 'Jenis customer update successfully',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 1000,
            })
          })
          .catch(e => {
            this.$swal.fire({
              title: 'Error!',
              html: this.jenis_customer + ' sudah diinput !',
              icon: 'error',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 2000,
            })
          })

        e.preventDefault()
        setTimeout(() => {
          this.$router.push({ name: 'jeniscustomer-index' })
        }, 3000)
      }
    },
  },
}
</script>
